<template>
  <b-row class="d-flex justify-content-md-center">
    <b-col
      class="shadow mt-5"
      lg="4"
      md="6"
    >
      <div class="bg-white mt-3 p-3">
        <h3 class="text-center">MADGE Entity Extractor</h3>
        <div class="text-center">Please login!</div>
        <div>
          <b-form-group
            class="mt-2"
            label-align="left"
            label-for="input-1"
            label="Username"
          >
            <b-form-input
              id=input-1
              v-model="form.username"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="mt-2"
            label-align="left"
            label="Password"
          >
            <b-form-input
              v-model="form.password"
              type="password"
            ></b-form-input>
          </b-form-group>
          <div
            v-if="erorrs.length"
            class="my-2"
          >
            <b-alert
              v-for="(err, i) in erorrs"
              :key="i"
              show
              variant="danger"
            >
              {{ err }}
            </b-alert>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <b-button
              class="ml-1 w-25"
              type="submit"
              variant="success"
              squared
              @click="login"
            >
              Login
            </b-button>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        username: null,
        password: null
      },
      erorrs: []
    };
  },
  computed: {
    isFormDirty() {
      let isDirty = false;
      Object.keys(this.form).forEach((field) => {
        if (this.form[field]) {
          isDirty = true;
        }
      })
      return isDirty;
    }
  },
  watch: {
    isFormDirty: function (isDirty) {
      if (!isDirty) this.erorrs = [];
    },
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("login", this.form);
        this.$nextTick(() => {
          this.$router.push({ name: "Home" });
        })
      } catch (e) {
        this.pushErrorMessage(e.message);
      }
    },
    pushErrorMessage(message) {
      if (this.erorrs.includes(message)) return;
      this.erorrs.push(message);
    }
  }
}
</script>
